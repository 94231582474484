<script setup lang="ts">
import Logo from '@/components/utils/Logo.vue';
import SecondaryButton from '@/components/utils/SecondaryButton.vue';
import Navbar from './Navbar.vue';
import {onMounted, ref, watch} from 'vue';
import { useFocusWithin } from '@vueuse/core';
import type { HeaderProps } from './types';
import LanguageSwitch from "@components/utils/LanguageSwitch.vue";

const showMobileMenu = ref<boolean>(false)
const showDropdown = ref<number | null>(null)

const target = ref()
const { focused } = useFocusWithin(target)

watch(focused, (focused) => {
  if (!focused && !showMobileMenu.value) showDropdown.value = null
})

type Props = HeaderProps

defineProps<Props>()

// check if we're under the /blog route
// error: window is not defined
const isBlogUrl = ref(true);
onMounted(() => {
  // isBlogUrl.value = window.location.pathname?.includes('/blog');
});

</script>

<template>
  <nav ref="target" class='sticky top-0 left-0 z-200 isolate h-max w-full'>
    <div 
      class="w-full flex" 
      data-header-slot-banner
    >
      <slot name="banner" ></slot>
    </div>
    <div 
      data-header-body
      :class="[
        'duration-500 ease-in-out',
        showMobileMenu ? 'h-screen w-screen bg-brand-background overflow-y-auto' : 'bg-brand-background/90 backdrop-blur-sm h-22 w-full'
      ]"
    >
      <div class="container relative w-full h-full">
        <div :class="[
          'relative w-full',
          showMobileMenu ? 'h-full' : 'h-22'
        ]">
          <div :class="[
            'absolute left-0 duration-100 top-1/2 -translate-y-1/2 ease-in-out',
            showMobileMenu ? 'opacity-0 invisible' : 'opacity-100 visible'
          ]">
            <Logo />
          </div>
          <div class="flex absolute top-1/2 -translate-y-1/2 right-0 items-center gap-x-4 lg:hidden">

            <LanguageSwitch
                v-if="isBlogUrl && !showMobileMenu"
                client:load />

            <SecondaryButton
                v-if="!showMobileMenu"
                href="https://app.dipsway.com"
            >
              <p>{{ $t('login') }}</p>
            </SecondaryButton>
            <!-- open menu button -->
            <button v-if="!showMobileMenu" @click="showMobileMenu = !showMobileMenu"
              class="cursor-pointer grid place-content-centerz-100">
              <span class="sr-only">open menu</span>
              <span class="text-3xl inline-block i-ion:menu" />
            </button>
          </div>
          <!-- submenu back button -->
          <button v-if="showDropdown !== null && showMobileMenu" @click="showDropdown = null"
            class="cursor-pointer grid place-content-center lg:hidden absolute top-0 left-0 h-22 flex items-center z-100">
            <span class="sr-only">menu back</span>
            <span class="text-3xl inline-block i-ic:round-arrow-back-ios" />
          </button>
          <div :class="[
            'relative flex ease-in duration-600',
            'lg:(absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 opacity-100 visible pointer-events-auto)',
            showMobileMenu ? 'opacity-100 visible pointer-events-auto' : 'opacity-0 pointer-events-none invisible'
          ]">
            <Navbar 
              :items
              v-model="showDropdown" 
              :showMobileMenu="showMobileMenu" 
            />
          </div>

          <LanguageSwitch
              v-if="isBlogUrl"
              class="lt-lg:hidden absolute top-1/2 right-22 xl:right-22 -translate-y-1/2"
              client:load

          />
          <SecondaryButton
              class="lt-lg:hidden absolute top-1/2 right-0 -translate-y-1/2"
              href="https://app.dipsway.com"
          >
            <p>{{ $t('login') }}</p>

          </SecondaryButton>

          <!-- close menu button -->
          <button v-if="showMobileMenu" @click="showMobileMenu = !showMobileMenu"
            class="cursor-pointer grid place-content-center absolute top-0 right-0 h-22 lg:hidden z-100">
            <span class="sr-only">close menu</span>
            <span class="text-3xl inline-block i-mingcute:close-line" />
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
