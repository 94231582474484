<script setup lang="ts">
import { formatDate } from '@vueuse/core';
import UserChip from '../UserChip.vue';
import { type Props } from './types';

defineProps<Props>()

</script>

<template>
  <div class="inline-block relative">
    <span class="font-bold text-4 sm:text-5.5">
      <slot name="header"/>
    </span>
    <div class="flex gap-x-3 gap-y-4 sm:items-center mt-2 sm:flex-row flex-col">
      <span class="text-brand-secondary">
        {{ formatDate(date, 'MMMM DD, YYYY') }}
      </span>
      <div class="w-1 h-1 rounded-full bg-brand-stroke/10 sm:inline-block hidden" />
      <div class="flex items-center gap-x-2.5 sm:order-none order-first">
        <div class="flex items-center -space-x-4">
          <UserChip 
            v-for="(author, index) in authors" 
            :key="index"
            :name="author.name"
            v-bind="(author.profilePicture ? {profilePicture: author.profilePicture} : {})"
          />
        </div>
        <div class="text-brand-secondary">
          <template 
            v-for="(author, index) in authors"
            :key="index"
          >
            <span>{{ author.name }} </span>
            <span v-if="authors.length !== index + 1"> & </span>  
          </template>
        </div>
      </div>
    </div>
    <div>
      <slot name="content" />
    </div>
  </div>
</template>