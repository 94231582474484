
<script setup>
import {useI18n} from "vue-i18n";
const {locale} = useI18n({
  useScope: 'global'
});
</script>
<template>
  <a :href="`/${locale?.slice(0,2)}`" class="flex items-center gap-x-4">
    <span class="i-dipsway-logo text-7" />
    <span class="text-sm font-bold">DipSway</span>
  </a>
</template>