<script setup lang="ts">
import Link from '@/components/utils/Link.vue';
import PrimaryButton from '@/components/utils/PrimaryButton/PrimaryButton.vue';
import type { FlyoutLinksAndFeatureProps } from '../types';

type Props = FlyoutLinksAndFeatureProps

defineProps<Props>()

</script>

<template>
  <div 
    :class="[
      'flex flex-col gap-y-5 gap-x-7 w-full',
      'lg:(flex-row)'
    ]"
  >
    <div class="pt-3.5 lg:min-w-48 shrink-0 flex flex-col">
      <div class="text-5.5 font-bold">
        {{ title }}
      </div>
      <Link 
        v-for="(link, index) in links" 
        :key="index" 
        :href="link.href" 
        class="mt-3"
      >
        {{ link.label }}
      </Link>
    </div>
    <div class="rounded-3 lt-lg:mt-12 border border-brand-primary inline-block relative overflow-hidden isolate lg:min-w-144 group/button">
      <!-- lighting effect start -->
      <div 
        :class="[
          'bg-effect-lighting bg-brand-gradient opacity-70 -z-5',
          'absolute left-1/2 top-1/2 -translate-1/2',
          'aspect-video w-[150%]',
        ]" 
      />
      <!-- lighting effect end -->
      <div class="inline-block relative py-6 px-8 rounded-3">
        <span 
          v-if="feature?.icon"
          :class="['h-8 w-8 text-brand-primary inline-block', feature.icon]" 
        />
        <p class="font-bold text-4 sm:text-5.5 max-w-[25ch] mt-2">
          {{ feature.header }}
        </p>
        <p class="text-brand-secondary mt-2" >
          {{ feature.descripiton }}
        </p>
        <div>
          <a :href="feature.cta.href" >
            <span class="inset-0 absolute" ></span>
            <PrimaryButton 
              class="mt-14" 
              as="span"
              size="sm"
            >
              {{ feature.cta.label }}
            </PrimaryButton>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>