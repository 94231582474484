<script setup lang="ts">
import { watch, type Component } from 'vue';
import FlyoutFeaturedPostWithLinks from './flyouts/FlyoutFeaturedPostWithLinks.vue'
import FlyoutLinksAndFeature from './flyouts/FlyoutLinksAndFeature.vue'
import type { FlyoutType, NavbarProps, NavigationItemType } from './types';

type Props = NavbarProps

const props = defineProps<Props>()
const showDropdown = defineModel<number | null>()

const flyoutComponentsMap: Record<FlyoutType, Component> = {
  'featured-post-with-links': FlyoutFeaturedPostWithLinks,
  'links-and-feature': FlyoutLinksAndFeature
}

function flyoutHandler (index: number, type: NavigationItemType) {
  if(type === 'flyout') showDropdown.value = index
}

function mouseLeave(type: NavigationItemType) {
  if(type === 'flyout' && !props.showMobileMenu) showDropdown.value = null 
}

watch(() => props.showMobileMenu, (show) => {
  if(show) showDropdown.value = null
})

</script>

<template>
    <div
      :class="[
        'flex flex-col gap-y-4 pt-22',
        'lg:(border-2 border-brand-stroke/10 rounded-full flex-row items-center p-2 pt-2 w-max h-max relative)',
      ]"
    >
      <div
        v-for="(item, index) in items" 
        :key="index"
        @mouseover="flyoutHandler(index, item.type)"
        @mouseleave="mouseLeave(item.type)"
        @keydown.enter="flyoutHandler(index, item.type)"
        :class="[
          'py-1 lg:px-3.75',
        ]"  
      >
        <template v-if="item.type === 'flyout'">
          <Transition
            enter-active-class="lt-lg:(duration-300 ease-in-out)"
            leave-active-class="lt-lg:(duration-300 ease-in-out)"
            enter-from-class="lt-lg:(-translate-x-15 opacity-0)"
            leave-to-class="lt-lg:(-translate-x-15 opacity-0)"
          >
            <button
              v-if="showDropdown === null || !showMobileMenu"
              :class="[
                'flex items-center gap-x-1 relative',
                !showMobileMenu && 'lt-lg:hidden',
                'before:(content-empty absolute -inset-y-1 -lg:inset-x-3 duration-300 ease-in-out lg:(bg-white/0 rounded-full))',
                'hover:before:(lg:(bg-white/5))'
              ]"
            >
              <span class="font-medium text-3xl lg:(text-sm) relative">
                {{ item.label }}
              </span>
              <span class='lg:text-xl lt-lg:(text-3xl mt-1) i-material-symbols:keyboard-arrow-down relative'/>
            </button>
          </Transition>
          <Transition
            enter-active-class="duration-400 ease-in-out dropdown-enter-active lg:delay-200"
            :enter-from-class="showMobileMenu ? 'lt-lg:(translate-x-15) opacity-0 dropdown-enter-from' : 'lt-lg:(translate-y-15) opacity-0 dropdown-enter-from'"
            leave-active-class="duration-400 ease-in-out dropdown-leave-active pointer-events-none"
            :leave-to-class="showMobileMenu ? 'lt-lg:(translate-x-15) opacity-0 dropdown-leave-to' : 'lt-lg:(translate-y-15) opacity-0 dropdown-leave-t'"
          >
            <div 
              v-if="showDropdown === index" 
              :class="[
                'absolute top-22 z-1000 lt-lg:(pb-10 w-full)',
                'lg:(top-full -translate-y-2.5 pt-5 left-1/2 -translate-x-1/2 w-max)',
              ]"
            >
              <div class="p-6 lt-lg:w-full lg:(w-max border-2 border-brand-stroke/10 rounded-4.5) bg-brand-background">
                 <div 
                  :class="[
                    'lg:[.dropdown-enter-active_&]:(duration-300 ease-in-out lg:delay-200)', 
                    'lg:[.dropdown-enter-from_&]:(translate-y-8)',
                    'lg:[.dropdown-leave-active_&]:(duration-300 ease-in-out)',
                    'lg:[.dropdown-leave-to_&]:(translate-y-8)',   
                  ]"
                 >
                  <component 
                    v-bind="item.flyout"
                    :is="flyoutComponentsMap[item.flyout.type]" 
                  />
                 </div>
              </div>
            </div>
          </Transition>
        </template>
        <template v-else-if="item.type === 'link'" >
          <Transition 
            enter-active-class="lt-lg:(duration-300 ease-in-out)"
            leave-active-class="lt-lg:(duration-300 ease-in-out)"
            enter-from-class="lt-lg:(-translate-x-15 opacity-0)"
            leave-to-class="lt-lg:(-translate-x-15 opacity-0)"
          >
            <a
              v-if="showDropdown === null || !showMobileMenu"
              :class="[
                'font-medium text-3xl flex lg:(text-sm) relative',
                'before:(content-empty absolute -inset-y-1 -lg:inset-x-3 duration-300 ease-in-out lg:(bg-brand-primary/0 outline-2 outline-brand-primary/0 outline-solid rounded-full))',
                'hover:before:(lg:(bg-brand-primary/20 outline-brand-primary))',
                !showMobileMenu && 'lt-lg:hidden',
                item.isHighlighted && 'text-brand-gradient'
              ]"
              :href="item.href"
            >
              {{ item.label }}
            </a>
          </Transition>
        </template>
      </div>
    </div>
</template>
