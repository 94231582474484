<script setup lang="ts">
import Link from '@/components/utils/Link.vue';
import PostPreview from '@/components/utils/PostPreview/PostPreview.vue';
import PrimaryButton from '@/components/utils/PrimaryButton/PrimaryButton.vue';
import type { FlyoutFeaturedPostWithLinksProps } from '../types';

type Props = FlyoutFeaturedPostWithLinksProps

defineProps<Props>()

</script>

<template>
  <div 
    :class="[
      'flex flex-col gap-y-5 gap-x-7 w-full',
      'lg:(flex-row)'
    ]"
  >
    <div class="pt-3.5 lg:min-w-48 shrink-0 flex flex-col">
      <div class="text-5.5 font-bold">
        {{ title }}
      </div>
      <Link 
        v-for="(link, index) in links" 
        :key="index" 
        :href="link.href" 
        class="mt-3"
      >
        {{ link.label }}
      </Link>
    </div>
    <div class="rounded-3 lt-lg:mt-12 border border-brand-primary inline-block relative overflow-hidden isolate lg:min-w-144 group/button">
      <img 
        v-if="post?.cover" 
        class="mx-auto w-2/3 absolute left-1/2 top-1/2 -translate-1/2 opacity-5"
        :src="post.cover.img.src" 
        :alt="post.cover.alt"
      />
      <!-- lighting effect start -->
      <div 
        :class="[
          'bg-effect-lighting bg-brand-gradient opacity-70 -z-5',
          'absolute left-1/2 top-1/2 -translate-1/2',
          'aspect-video w-[150%]',
        ]" 
      />
      <!-- lighting effect end -->
      <PostPreview 
        class="py-6 px-8 rounded-3"
        :authors="post.authors" 
        :date="post.date"
      >
        <template #header>
          {{ post.header }}
        </template>
        <template #content>
          <a :href="post.cta.href" >
            <span class="inset-0 absolute" ></span>
            <PrimaryButton class="mt-14" as="span">
              {{ post.cta.label }}
            </PrimaryButton>
          </a>
        </template>
      </PostPreview>
    </div>
  </div>
</template>